import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Button, Heading, Link, SimpleGrid, Stack } from '@chakra-ui/react'
// import ReactMarkdown from 'react-markdown'
import StructuredBreadcrumbs from '../components/StructuredData/breadcrumbs'
import Helmet from 'react-helmet'
import Nav from '../components/nav'

const brandsHubTemplate = (props) => {
    const { filter } = props.pageContext
    const { allBrands } = props.data
    const BrandItem = (props) => {
        return (
            <Link
                display='flex'
                justifyContent='space-between'
                as={GatsbyLink}
                to={props.brand.slug}
                pr='1.5rem'
            >
                {props.brand.name}
                {props.brand.ad ?
                    <Box
                        borderRadius='50%'
                        backgroundColor='blue.brand'
                        color='white'
                        fontSize='12px'
                        w='20px'
                        h='20px'
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p='.05rem'
                        ml='.5rem'
                        as="span">{props.brand.ad.length}
                    </Box>
                    : null}
            </Link>
        )
    }
    // const intl = useIntl();
    // console.log( intl )
    const baseBreadcrumb = { "name": process.env.GATSBY_LANG === 'fr' ? 'Marques' : 'Brands', "slug": process.env.GATSBY_LANG === 'fr' ? '/marques/' : '/brands/' };
    const subCategory = filter ? { "name": process.env.GATSBY_LANG === 'fr' ? 'Chantiers' : 'Shipyard', "slug": process.env.GATSBY_LANG === 'fr' ? '/marques/chantiers/' : '/brands/builders/' } : null



    return (
        <LayoutPublic lang={process.env.GATSBY_LANG}>
            <Helmet>
                <meta
                    title={
                        process.env.GATSBY_LANG === 'fr'
                            ?
                            `Marques et chantiers nautiques sur Wanaboat`
                            :
                            `All boatyards of used boats currently for sale on iWannaboat`
                    }
                    name='description'
                    content={
                        process.env.GATSBY_LANG === 'fr'
                            ?
                            `Toutes les chantiers nautiques des bateaux d'occasion actuellement à la vente sur Wanaboat`
                            :
                            `All boatyards of used boats currently for sale on iWannaboat`
                    }
                />
            </Helmet>
            <Box
                px={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
                py={{ base: 0, md: "1rem", lg: "1rem", xxl: "2rem" }}
            >
                <StructuredBreadcrumbs
                    items={[
                        baseBreadcrumb,
                        subCategory,
                        null
                    ]

                    }
                />
                <Stack mt='2rem' spacing='2rem' p={{ base: '2rem 1rem', lg: '0' }}>
                    <Nav />
                    <Box>
                        <Button as={GatsbyLink} variant="outline" to='../'>
                            ← Retour
                        </Button>
                    </Box>
                    <Heading as="h1" color='gray.700'>
                        {props.data.page.title}
                    </Heading>
                    <Box className="wysiwyg">
                        {/* <ReactMarkdown source={props.data.page.childContentfulPageBodyTextNode.body} /> */}
                    </Box>
                    <SimpleGrid
                        columns={{ base: 2, lg: 3, xl: 4 }}
                        gap={{ base: '1rem', lg: '.5rem' }}
                    >
                        {
                            allBrands.edges.map(brand =>
                                filter ?
                                    brand.node[filter] ?
                                        <BrandItem
                                            key={`brand-item-${brand.node.contentful_id}`}
                                            brand={brand.node}
                                        />
                                        : null
                                    :
                                    <BrandItem
                                        key={`brand-item-${brand.node.contentful_id}`}
                                        brand={brand.node}
                                    />
                            )}
                    </SimpleGrid>
                </Stack>
            </Box>
        </LayoutPublic>
    )
}

export default brandsHubTemplate


export const pageQuery = graphql`
  query brandsHubQuery( $lang: String!, $contentfulPageID: String! ) {
    page: contentfulPage(
        contentful_id: { eq: $contentfulPageID }
        node_locale: { eq: $lang }
    ) {
        contentful_id
        title
		childContentfulPageBodyTextNode{
            body
        }
        data{
            usersToThank{ name }
        }
    }
    allBrands: allContentfulBrand(
      filter: {
        node_locale: { eq: $lang }
      }
    ) {
      edges {
        node {
          id
          name
          contentful_id
          slug
          isBoatBuilder
          isSailMaker
          isEquipementBuilder
          ad{
              id
          }
        }
      }
    }
  }
`;

